@tailwind base;

body { @apply bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-300 text-sm 2xl:text-base }
border { @apply border-gray-400 dark:border-gray-700 }

h1 { @apply leading-normal font-extrabold text-2xl sm:text-3xl 2xl:text-4xl }
h2 { @apply leading-normal font-bold text-xl sm:text-2xl 2xl:text-3xl }
h3 { @apply leading-normal font-bold text-lg sm:text-xl 2xl:text-2xl }
h4 { @apply leading-normal font-bold text-base sm:text-lg 2xl:text-xl }

label { @apply font-semibold opacity-70 }

video { @apply object-contain; }

* {
    transition: border-color .1s ease,background-color .2s ease,color .1s ease
}

button:active { @apply outline-none }
button:focus { @apply outline-none }

*:focus { @apply outline-none }

a {@apply text-sky-500}
a:hover {@apply text-sky-600}

svg text { @apply fill-current }

hr { @apply dark:border-gray-700 }


@tailwind components;


/* Header */
.header-with-links .links{
    @apply fixed top-0 left-0 right-0 px-6 py-10 pb-4 bg-opacity-95 bg-white dark:bg-gray-800 shadow-md invisible opacity-0 transform-gpu transition-all mt-10 duration-300 z-10  dark:border-gray-700
}
.header-with-links.show-links .links{
    @apply visible opacity-100 mt-14 2xl:mt-16
}

/* Spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid #fff;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
     transform: rotate(0deg);
  }
  100% {
     transform: rotate(360deg);
  }
}


.animate-ease-1 {
    transition: all .1s ease
}

.animate-ease-2 {
    transition: all .2s ease
}

.animate-ease-3 {
    transition: all .3s ease
}

.animate-ease-5 {
    transition: all .5s ease
}

video::cue {
    @apply text-white leading-normal opacity-75;
}

video::-webkit-media-text-track-container {
    overflow: visible !important;
    transform: translateY(-22px) !important;
}

.video-wrapper figure {
    display: block;
    background-size: cover;
    background-position: 50%
}

.react-player,.video-wrapper figure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.video-range {
    transition: all .3s ease;
    height: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    --tw-bg-opacity:1;background-color: rgba(253,253,253,var(--tw-bg-opacity));
    border-radius: 9999px;
    cursor: pointer;
    flex: 1 1 0%;
    padding: 0
}

.video-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 9999px;
    height: .75rem;
    width: .75rem;
    background-color: #64d5ca
}

.video-player-container .video-full-play-pause-button {
    background: linear-gradient(rgba(0,0,0,.04), rgba(0,0,0,.56));
    transition: opacity 1s cubic-bezier(.4,0,.6,1);
    opacity: 0
}

.video-player-container:hover .video-full-play-pause-button,
.video-player-container.is-pause .video-full-play-pause-button{
    opacity: 1
}

.video-player-container .video-bottom-control {
    transition: opacity .4s cubic-bezier(0,0,.2,1) .05s,transform .5s cubic-bezier(0,0,.2,1);
    transform: translateY(20px);
    opacity: 0
}

.video-player-container:hover .video-bottom-control,
.video-player-container.is-pause .video-bottom-control {
    opacity: 1;
    transform: translateY(0)
}

.video-thumb-container:hover .play-button {
    @apply scale-110;
}

.video-control-menu-item {
    @apply cursor-pointer opacity-70 hover:opacity-100 px-2
}


.editable-fullname .edit-pencil {
    @apply opacity-50 transition-all duration-200;
}
.editable-fullname:hover .edit-pencil {
    @apply opacity-100;
}

.bounce {
    transition: all 0.3s cubic-bezier(0.1, 1.11, 0.41, 2.05);
}
.bounce-5 {
    transition: all 0.5s cubic-bezier(0.1, 1.11, 0.41, 2.05);
}



@keyframes slidein {
    from {
        transform: translateX(100%)
    }

    to {
        transform: translateX(0%)
    }
}



@tailwind utilities;



.course-item:hover .choose-course-arrow {
    @apply opacity-100 translate-x-0
}


@media (max-width: 1023px) {
    .modal-height {
        min-height: calc(100% - 3rem)
    }
}




/* ma-shan-zheng-regular - latin_chinese-simplified */
@font-face {
    font-family: 'Ma Shan Zheng';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
        url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/ma-shan-zheng-v8-latin_chinese-simplified-regular.svg#MaShanZheng') format('svg'); /* Legacy iOS */
}

/* zcool-xiaowei-regular - latin_chinese-simplified */
@font-face {
    font-family: 'ZCOOL XiaoWei';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/zcool-xiaowei-v8-latin_chinese-simplified-regular.svg#ZCOOLXiaoWei') format('svg'); /* Legacy iOS */
  }

/* zcool-kuaile-regular - latin_chinese-simplified */
@font-face {
    font-family: 'ZCOOL KuaiLe';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/zcool-kuaile-v15-latin_chinese-simplified-regular.svg#ZCOOLKuaiLe') format('svg'); /* Legacy iOS */
}